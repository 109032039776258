import { breakpoint } from '@/helpers/BreakpointHelper';
import styled, { css } from 'styled-components';

const BrDesktop = css`
  display: initial;
  ${breakpoint(`mobile`)} {
    display: none;
  }
`;

const BrMobile = css`
  display: none;
  ${breakpoint(`mobile`)} {
    display: initial;
  }
`;

const BR = styled.br<{ only?: 'desktop' | 'mobile' }>`
  ${({ only }) => only === `desktop` && BrDesktop}
  ${({ only }) => only === `mobile` && BrMobile}
`;

export default BR;
